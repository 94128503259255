import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

import BannerInterior from '../../components/BannerInterior'
import BannerAgenda from '../../components/BannerAgenda'
import CateringItem from '../../components/CateringItem'

import poster from '../../images/banner-primer-curso.jpg'
import pic1 from '../../images/catering/primer-curso/crema-de-elote-y-pimento-amarrillo-1.jpg'
import pic2 from '../../images/catering/primer-curso/crema-de-espinaca-1.jpg'
import pic3 from '../../images/catering/primer-curso/crema-de-poro-y-papa-1.jpg'
import pic4 from '../../images/catering/primer-curso/ensaladas-de-lechugas-organicas-1.jpg'
import pic5 from '../../images/catering/primer-curso/1.jpg'
import pic7 from '../../images/catering/primer-curso/3.jpg'
import pic8 from '../../images/catering/primer-curso/4.jpg'

const platillos = [
	{
		img: pic1,
		titulo: 'Crema de elote y pimiento amarillo',
		descripcion: ''
	},
	{
		img: pic2,
		titulo: 'Crema de espinaca',
		descripcion: ''
	},
	{
		img: pic3,
		titulo: 'Crema de poro y papa',
		descripcion: ''
	},
	{
		img: pic4,
		titulo: 'Ensalada de lechugas orgánica',
		descripcion: ''
	},
	{
		img: pic5,
		titulo: 'Ceviche de camarón con mango',
		descripcion: ''
	},
	{
		img: pic7,
		titulo: 'Ensalada de betabel con pera, queso de cabra y vinagreta de balsamico',
		descripcion: ''
	},
	{
		img: pic8,
		titulo: 'Ensalada Barcelona, queso panela, fresas, arándonos y nuez garapiñada',
		descripcion: ''
	}
]

const Catering = () => (
	<Layout>

		<SEO title="Primer curso" />

		<BannerInterior
			small="Barcelona Catering"
			title="Primer curso"
			caption=""
			imgsrc={poster}
			textstyle="darks"
		/>

		<section className="platillos-blocks">
			<div className="container">
				<div className="row">

					{platillos.map((platillo, index) => (
						<div className="col-12 col-sm-6 col-md-4" key={index}>
							<CateringItem
								img={platillo.img}
								titulo={platillo.titulo}
								descripcion={platillo.descripcion}
								categoria="Primer curso"
							/>
						</div>
					))}

				</div>
			</div>
		</section>

		<BannerAgenda paddingtop="pt-7"/>

	</Layout>
)

export default Catering
